html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

.parent-container1 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
  margin-left: 30px;
  margin-top: 50px;
  margin-bottom: 20px;
}

.landing-page1 {
    min-height: 100%;
    background-color: #f7f7f7;
} 

.img {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    margin-right: 20px;
}

.row {
    display: flex;
    flex-direction: row;
}

.title{
    margin-top: 10px;
    margin-bottom: 40px;
}

.footer1 {
    background-color: #333;
    color: white;
    position: sticky;
    padding: 20px;
    text-align: center;
}
 