/* LandingPage.css */
.landing-page {
  background-color: #f7f7f7;
}

header {
  background-color: #eb455f;
  color: white;
  padding: 5px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
  margin-left: 20px;

}

.logo img {
  width: 60px;
  margin-right: 10px;
  border-radius: 5px;
}


h1 {
  font-size: 40px;
  margin: 0;
  margin-bottom: 20px;
}

h2 {
  font-size: 16px;
  margin: 0;
}

.brand_container {
  align-items: center;
  flex: 3;
  flex-wrap: wrap;
  display: flex;
  margin: 20px;
} 

.playstore-button {
  display: flex;
  align-items: center;
  background-color: #ed455f;
  color: white;
  border-radius: 4px;
  text-decoration: none;
}

.playstore-button img {
  width: 200px;
  margin-right: 0px;
}

.playstore-button1 img {
  width: 80%;
  max-width: 300px;
  margin-right: 0px;
}

.parent-container {
  display: flex;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.hero {
  flex: 2;
  background-color: #fff;
}

.hero-content {
  display: flex;
  justify-content: center;
}

.screenshot img {
  width: 80%;
  max-width: 500px;
  transform: rotate(-5deg);
  
}

.screenshot1 img {
  width: 80%;
  max-width: 500px;
  transform: rotate(5deg);
  
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px 0;
}

.feature {
  width: 300px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
  text-align: center;
}

.feature img {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
}

footer {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}

.attributions {
    color: white;
}

@media (max-width: 768px) {
  .parent-container {
    flex-direction: column; /* Change to vertical alignment */
    align-items: center; /* Center items vertically */
  }

  .topBar-brandName {
    display: none;
  }

  .hero, .brand_container {
    flex: 1; /* Equal width for both items */
  }
}





